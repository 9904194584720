import React, { ReactNode , useContext } from 'react'
import AuthContext from '../context/AuthContext';

type Props = {
  children?: ReactNode;
}

const RequireLogin = ({children}: Props) => {
    const { user } = useContext(AuthContext)

  return (
    <>
      { user? children : null }
    </>
  );  
}

export default RequireLogin

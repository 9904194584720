import React from 'react'
import { Container, Box, Typography } from '@mui/material'

const NotFoundPage = () => {
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          minHeight: '320px',
        }}
      >
        <Box
          sx={{
            marginTop: '-8vh',
            fontWeight: 'bold',
            fontSize: '3.375rem',
          }}
        >
          4
          <span role="img" aria-label="Crying Face">
            😢
          </span>
          4
        </Box>
        <Typography>Page not found.</Typography>
      </Box>
    </Container>
  );
}

export default NotFoundPage

import React, { ReactNode , useContext } from 'react'
import AuthContext from '../context/AuthContext';

type Props = {
  children?: ReactNode;
}

const RequireAdmin = ({children}: Props) => {
  const { isStuffUser } = useContext(AuthContext)

  return (
    <>
      { isStuffUser? children : null }
    </>
  );  
}

export default RequireAdmin
